import React, { useState } from "react";
import { styled } from "@mui/system";
import { Container, Typography, Box } from "@mui/material";
import { OrangeColor } from "../components/Const";
import Interior2 from "../assets/Interior2.jpg";
import Interior from "../assets/Interior.jpg";
import BusExterior from "../assets/BusExterior.jpg";
import colete2 from "../assets/colete2.jpg";

function Galerie() {
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const Galerie = styled("div")({
    backgroundImage: `url(${Interior2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "100%",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });
  return (
    <section>
      <Galerie>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            textAlign="center"
            variant="h2"
            component="h3"
            color={OrangeColor}
            sx={{ justifyContent: "center", pt: 18 }}
          >
            Galerie
          </Typography>
          <Typography
            textAlign="center"
            variant="h5"
            component="h4"
            color={"white"}
            sx={{ justifyContent: "center", py: 5 }}
          ></Typography>
        </Container>
      </Galerie>
      <Box sx={{}}>
        <Container>

          <div>
            <img src={Interior2} />
            <p className="legend">Interiorul autobuzului Setra</p>
          </div>
          <div>
            <img src={Interior} />
            <p className="legend">Intrarea autobuzului Setra</p>
          </div>
          <div>
            <img src={BusExterior} />
            <p className="legend">Exteriorul autobuzului Setra</p>
          </div>
          <div>
            <img src={colete2} />
            <p className="legend">Transportul coletelor</p>
          </div>
        </Container>
      </Box>
    </section>
  );
}

export default Galerie;
