import React from "react";
import NewsBG from "../assets/Interior2.jpg";
import { styled } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { OrangeColor } from "../components/Const";
import CardNoutati from "../components/CardNoutati";
import Fade from "react-reveal/Fade";

function News() {
  const NewsBackground = styled("div")({
    backgroundImage: `url(${NewsBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });
  return (
    <section>
      <NewsBackground>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Fade top>
            <Typography
              textAlign="center"
              variant="h2"
              component="h3"
              color={OrangeColor}
              sx={{ justifyContent: "center", pt: 18 }}
            >
              Promoții și Anunțuri
            </Typography>
          </Fade>
          <Fade bottom>
            <Typography
              textAlign="center"
              variant="h5"
              component="h4"
              color={"white"}
              sx={{ justifyContent: "center", py: 5 }}
            >
              Pentru a fi mereu la curent cu cele mai noi oferte și reduceri la
              serviciile noastre de transport international de persoane și
              mărfuri, vă încurajăm să vizitați regulat secțiunea noastră de
              Promotii și Anunțuri. Nu ratați șansa de a beneficia de avantajele
              oferite de
              <Typography variant="span" color={OrangeColor} sx={{ px: 1 }}>
                Briana Travel
              </Typography>
              !
            </Typography>
          </Fade>
        </Container>
      </NewsBackground>
      <Container style={{ marginBottom: "36px" }}>
        <Fade bottom>
          <CardNoutati />
        </Fade>
      </Container>
    </section>
  );
}

export default News;
