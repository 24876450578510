import React from "react";

const GoogleMapIframe = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.705680767718!2d27.565813376239948!3d47.16361847115266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb6ef6026e7f%3A0x225997d3a38cd58e!2sBRIANA%20TRAVEL!5e0!3m2!1sro!2sro!4v1696692835354!5m2!1sro!2sro"
      width="600"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default GoogleMapIframe;
