import Braila from "../../assets/MiniCity/Braila.jpeg";
import Galati from "../../assets/MiniCity/Galati.jpg";
import Tecuci from "../../assets/MiniCity/Tecuci.jpg";
import Focsani from "../../assets/MiniCity/Focsani.jpg";
import Adjud from "../../assets/MiniCity/Adjud.jpg";
import Barlad from "../../assets/MiniCity/Barlad.jpg";
import Vaslui from "../../assets/MiniCity/Vaslui.jpg";
import Husi from "../../assets/MiniCity/Husi.jpg";
import Iasi from "../../assets/MiniCity/Iasi.jpeg";
import TgFrumos from "../../assets/MiniCity/TgFrumos.jpg";
import Pascani from "../../assets/MiniCity/Pascani.jpg";
import TgNeamt from "../../assets/MiniCity/TgNeamt.jpg";
import Toplita from "../../assets/MiniCity/Toplita.jpg";
import Reghin from "../../assets/MiniCity/Reghin.jpg";
import TgMures from "../../assets/MiniCity/TgMures.jpg";
import Cluj from "../../assets/MiniCity/Cluj.jpg";
import Oradea from "../../assets/MiniCity/Oradea.jpg";
///////////////
import Roman from "../../assets/MiniCity/Roman.jpg";
import Bacau from "../../assets/MiniCity/Bacau.jpg";
import Onesti from "../../assets/MiniCity/Onesti.jpg";
import Brasov from "../../assets/MiniCity/Brasov.jpg";
import Sibiu from "../../assets/MiniCity/Sibiu.jpg";
import Deva from "../../assets/MiniCity/Deva.jpg";
import Arad from "../../assets/MiniCity/Arad.jpg";

export const TraseuRomaniaUnu = [
  {
    id: 1,
    oras: "Braila",
    img: Braila,
  },
  {
    id: 2,
    oras: "Galati",
    img: Galati,
  },
  {
    id: 3,
    oras: "Tecuci",
    img: Tecuci,
  },
  {
    id: 4,
    oras: "Focsani",
    img: Focsani,
  },
  {
    id: 5,
    oras: "Adjud",
    img: Adjud,
  },
  {
    id: 6,
    oras: "Barlad",
    img: Barlad,
  },
  {
    id: 7,
    oras: "Vaslui",
    img: Vaslui,
  },
  {
    id: 8,
    oras: "Husi",
    img: Husi,
  },
  {
    id: 9,
    oras: "Iasi",
    img: Iasi,
  },
  {
    id: 10,
    oras: "Târgu Frumos",
    img: TgFrumos,
  },
  {
    id: 11,
    oras: "Pascani",
    img: Pascani,
  },
  {
    id: 12,
    oras: "Tg.Neamt",
    img: TgNeamt,
  },
  {
    id: 13,
    oras: "Toplita",
    img: Toplita,
  },
  {
    id: 14,
    oras: "Reghin",
    img: Reghin,
  },
  {
    id: 15,
    oras: "Tg.Mures",
    img: TgMures,
  },
  {
    id: 16,
    oras: "Cluj",
    img: Cluj,
  },
  {
    id: 17,
    oras: "Oradea",
    img: Oradea,
  },
];
export const TraseuRomaniaDoi = [
  {
    id: 1,
    oras: "Iasi",
    img: Iasi,
  },
  {
    id: 2,
    oras: "Târgu Frumos",
    img: TgFrumos,
  },
  {
    id: 3,
    oras: "Roman",
    img: Roman,
  },
  {
    id: 4,
    oras: "Bacau",
    img: Bacau,
  },
  {
    id: 5,
    oras: "Onesti",
    img: Onesti,
  },
  {
    id: 6,
    oras: "Brasov",
    img: Brasov,
  },
  {
    id: 7,
    oras: "Sibiu",
    img: Sibiu,
  },
  {
    id: 8,
    oras: "Deva",
    img: Deva,
  },
  {
    id: 9,
    oras: "Arad",
    img: Arad,
  },
];
