import React from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";

const FacebookButton = styled(IconButton)({
  backgroundColor: "#1877f2", // Facebook blue color
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0a4c8d", // Darker Facebook blue on hover
  },
});

const handleFacebookClick = () => {
  window.open("https://www.facebook.com/brianatraveliasi", "_blank");
};

const FacebookIconButton = () => (
  <FacebookButton
    onClick={handleFacebookClick}
    aria-label="Facebook"
    style={{ marginLeft: "12px", marginRight: "12px" }}
  >
    <FacebookIcon />
  </FacebookButton>
);

export default FacebookIconButton;
