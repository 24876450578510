import { Box, Divider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import CardNoutati from "./CardNoutati";

function Noutati() {
  return (
    <div>
      <Container>
        <Box sx={{ py: 5 }}>
          <Typography variant="h3" sx={{ color: "#ff5722" }}>
            Noutăți și Promoții
          </Typography>
          <Typography variant="h6">
            Profită acum de promoțiile noastre avantajoase și beneficiază de
            reduceri la serviciile de transport de persoane și mărfuri. Oricare
            ar fi destinația preferată, Romania sau Italia, noi suntem aici să
            îți oferim cele mai bune soluții de transport la prețuri
            competitive.
          </Typography>
          <Divider sx={{ height: "1px", backgroundColor: "#ff5722" }} />
          <CardNoutati />
        </Box>
      </Container>
    </div>
  );
}

export default Noutati;
