import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
import marker from "../assets/pin.png";
import { Box } from "@mui/system";
function Maps() {
  const position = [47.163654666692366, 27.568434659204033];
  const zoomLevel = 17;

  const myIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    popupAnchor: [-0, -0],
    iconSize: [55, 85],
  });

  const map = (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.705680767718!2d27.565813376239948!3d47.16361847115266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb6ef6026e7f%3A0x225997d3a38cd58e!2sBRIANA%20TRAVEL!5e0!3m2!1sro!2sro!4v1696692835354!5m2!1sro!2sro"
      width="600"
      height="450"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
  return (
    <Box sx={{ my: 5 }}>
      <MapContainer
        center={position}
        zoom={zoomLevel}
        style={{
          height: "350px",
          width: "auto",
        }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={myIcon}>
          <Popup>Briana Travel</Popup>
        </Marker>
      </MapContainer>
    </Box>
  );
}
export default Maps;
