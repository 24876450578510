import React, { useState, useEffect, useRef } from "react";
import Logo from "../assets/Logo.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import PublicIcon from "@mui/icons-material/Public";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
import { Link } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import { NavMenu, OrangeColor } from "./Const";

function NavBar({ height, setHeight }) {
  const listRef = useRef();

  const getListSize = () => {
    const newHeight = listRef.current.clientHeight;
    setHeight(newHeight);
  };
  useEffect(() => {
    getListSize();
  });

  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColor = scrollY > 10 ? OrangeColor : "rgba(0,0,0, 0.1)";
  const transition = "background-color 0.8s ease-in-out";
  const colorText = scrollY > 10 ? "white" : "#d50000";
  const transitionText = "color 0.8s ease-in-out";
  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{ backgroundColor, transition }}
      elevation={0}
      ref={listRef}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ color: `${colorText}`, transition: `${transitionText}` }}
        >
          <PublicIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Briana Travel
          </Typography>
          <PublicIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Briana Travel
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {NavMenu.map((item) => (
              <Link
                key={item.id}
                to={item.link}
                style={{
                  color: `${colorText}`,
                  textDecoration: "none",
                  marginRight: "10px",
                }}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "inherit",
                    display: "block",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  {item.text}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                sx: {
                  width: "100%",
                  maxWidth: "unset",
                  left: "0px !important",
                  color: "#ff7043",
                  borderRadius: 0,
                  backgroundColor: "rgba(0,0,0, 0.9)",
                  top: "64px",
                },
              }}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {NavMenu.map((item) => (
                <MenuItem key={item.id} onClick={handleCloseNavMenu}>
                  <Link
                    key={item.id}
                    to={item.link}
                    style={{
                      color: "#ff5722",
                      textDecoration: "none",
                      marginRight: "2px",
                    }}
                  >
                    <Typography textAlign="center">{item.text}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
