import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Container,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { Box } from "@mui/system";

function Intrebari() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div
      style={{
        backgroundColor: "#f3f5f6",
        paddingTop: " 20px",
        paddingBottom: "20px",
      }}
    >
      <Container sx={{ flexGrow: 1 }}>
        <Box>
          <HelpCenterIcon fontSize="large" sx={{ color: "#ff5722" }} />
          <HelpCenterIcon fontSize="large" sx={{ color: "#ff5722" }} />
          <HelpCenterIcon fontSize="large" sx={{ color: "#ff5722" }} />
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="h3">Întrebări Frecvente</Typography>
            <Typography variant="subtitle1">
              Răspunsuri la cele mai frecvente întrebări despre serviciile
              noastre de transport. Consultă secțiunea de Întrebări și
              Răspunsuri.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#ff5722", my: 3 }}
            >
              Aveți o întrebare?
            </Typography>

            <Box sx={{ p: 4, backgroundColor: "rgba(255, 87, 34, 0.8)" }}>
              <Typography variant="h6" sx={{ color: "#ffffff" }}>
                0745 902 990 & 0742 996 899
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ mb: 2 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#ff5722" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "100%",
                    flexShrink: 0,
                    color: "#ff5722",
                    fontWeight: "bold",
                  }}
                >
                  Care este prețul per kilogram pentru coletărie?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Prețul pentru fiecare kilogram de bagaj este de 1 euro în
                  stațiile principale și cel de 1,30-1,50 euro pentru preluările
                  de la domiciliu.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ mb: 2 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#ff5722" }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{
                    width: "100%",
                    flexShrink: 0,
                    color: "#ff5722",
                    fontWeight: "bold",
                  }}
                >
                  Care este prețul biletului de călătorie?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Prețul biletului este de 120 euro pentru fiecare segment de
                  călătorie.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Intrebari;
