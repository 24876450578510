import React from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import TikTokIcon from "@mui/icons-material/AlternateEmail";

const TikTokButton = styled(IconButton)({
  backgroundColor: "#000", // TikTok black color
  color: "#fff",
  "&:hover": {
    backgroundColor: "#69C9D0", // TikTok blue color on hover
  },
});

const handleTikTokClick = () => {
  window.open("https://www.tiktok.com/@brianatravel?lang=en", "_blank");
};

const TikTokIconButton = () => (
  <TikTokButton onClick={handleTikTokClick} aria-label="TikTok">
    <TikTokIcon />
  </TikTokButton>
);

export default TikTokIconButton;
