import BgRomania from "../assets/road.jpeg";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { styled } from "@mui/material";
import { Typography, Container, Box, Grid, Divider } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  TraseuRomaniaUnu,
  TraseuRomaniaDoi,
} from "../components/arrays/TraseuRomania";

import { OrangeColor } from "../components/Const";

function Romania() {
  const RomaniaRoad = styled("div")({
    backgroundImage: `url(${BgRomania})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    // marginTop: 0,
    height: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });

  return (
    <div>
      <RomaniaRoad>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            textAlign="center"
            variant="h2"
            component="h3"
            color={OrangeColor}
            sx={{ justifyContent: "center", pt: 18 }}
          >
            Trasee Romania
          </Typography>
          <Typography
            textAlign="center"
            variant="h5"
            component="h4"
            color={"white"}
            sx={{ justifyContent: "center", py: 5 }}
          >
            Alegeti calatoria cu autocarele noastre pentru a va bucura de
            siguranta si confort in timpul deplasarii.
          </Typography>
        </Container>
      </RomaniaRoad>
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
                Traseu 1
              </Typography>
              <Typography sx={{ textAlign: "center", color: OrangeColor }}>
                Romania
              </Typography>
              <Timeline position="alternate">
                {TraseuRomaniaUnu.map((item) => (
                  <>
                    <TimelineItem key={item.id}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", overflow: "hidden" }}
                        align="right"
                        variant="h6"
                      >
                        {item.oras}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                        <TimelineDot sx={{ backgroundColor: OrangeColor }}>
                          <ArrowDropDownIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="div">
                          <img
                            style={{
                              height: 98,
                              width: 98,
                              borderRadius: 8,
                            }}
                            src={item.img}
                            alt={item.oras}
                          />
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: OrangeColor,
                  mb: 3,
                  fontWeight: "bold",
                }}
              >
                Retur
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Divider
                sx={{
                  display: { sm: "block", md: "none" },
                  height: "2px",
                  backgroundColor: OrangeColor,
                }}
              />
              <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
                Traseu 2
              </Typography>
              <Typography sx={{ textAlign: "center", color: OrangeColor }}>
                Romania
              </Typography>
              <Timeline position="alternate">
                {TraseuRomaniaDoi.map((item) => (
                  <>
                    <TimelineItem key={item.id}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", overflow: "hidden" }}
                        align="right"
                        variant="h6"
                        //color="text.secondary"
                      >
                        {item.oras}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                        <TimelineDot sx={{ backgroundColor: OrangeColor }}>
                          <ArrowDropDownIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="div">
                          <img
                            style={{
                              height: 98,
                              width: 98,
                              borderRadius: 8,
                            }}
                            src={item.img}
                            alt={item.oras}
                          />
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: OrangeColor,
                  mb: 3,
                  fontWeight: "bold",
                }}
              >
                Retur
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default Romania;
