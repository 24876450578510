import "./App.css";
import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from "./components/NavBar";
import Main from "./pages/Main";
import Footer from "./components/Footer";

function App() {
  const [height, setHeight] = useState();

  return (
    <div className="App">
      <>
        <CssBaseline />
        <NavBar height={height} setHeight={setHeight} />
        <Main height={height} />
        <Footer />
      </>
    </div>
  );
}

export default App;
