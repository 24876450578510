import React from "react";
import { styled } from "@mui/system";
import { Box, Container, Typography, Grid, Divider } from "@mui/material";
import Link from "@mui/material/Link";
import { Facebook, Instagram } from "@mui/icons-material";
import TikTokIcon from "./../assets/TikTokIcon";
import AnpcSol from "./../assets/anpc-sol.png"
import AnpcSal from "./../assets/anpc-sal.png"
import BgFooter from "../assets/footer.jpg";
import CardMedia from "@mui/material/CardMedia";

const FooterBg = styled("footer")({
  backgroundImage: `url(${BgFooter})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  height: "auto",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1,
  },
});
function Footer() {
  return (
    <FooterBg>
      {" "}
      <Container sx={{ position: "relative", zIndex: 2 }}>
        {" "}
        <Box sx={{ py: 4 }}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" sx={{ color: "#ffffff", pt: 2 }}>
                Briana Travel
              </Typography>
              <Typography variant="h5" sx={{ color: "#ff5722", py: 2 }}>
                Transport Internațional Persoane
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                color="white"
                sx={{ pt: 2 }}
                gutterBottom
              >
                Contactați-ne
              </Typography>
              <Typography variant="body2" color="white">
                Ne gasiti in Iasi
              </Typography>
              <Typography variant="body2" color="white">
                Soseaua Nationala nr.80, incinta baza sportiva CFR
              </Typography>
              <Typography variant="body2" color="white">
                E-mail: brianatravel_brianatravel@yahoo.com
              </Typography>
              <Typography variant="body2" color="white">
                Telefon: 0040.745.90.29.90 - Romania 0039.3802.451.763 - Italia
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="h5"
                color="white"
                sx={{ pt: 2 }}
                gutterBottom
              >
                Urmărește-ne
              </Typography>
              <Grid item sx={{ scale: "120%", marginLeft: "30px" }}>
                <Link
                  href="https://www.facebook.com/brianatraveliasi"
                  target="_blank"
                  sx={{ color: "#ffffff", scale: "120%" }}
                >
                  <Facebook />
                </Link>
                <Link
                  href="https://www.facebook.com/brianatraveliasi"
                  target="_blank"
                  sx={{ color: "#ffffff", scale: "120%" }}
                >
                  <Instagram />
                </Link>
                <Link
                  href="https://www.tiktok.com/@brianatravel"
                  target="_blank"
                  sx={{ color: "#ffffff", scale: "150%" }}
                >
                  <TikTokIcon />
                </Link>
              </Grid>
              <Grid item sx={{ scale: "120%", marginLeft: "30px", marginTop: "20px" }}>
                <Link
                  href="https://anpc.ro/ce-este-sal/"
                  target="_blank"
                  sx={{ color: "#ffffff", scale: "100%" }}
                >
                  <CardMedia
                    component="img"
                    image={AnpcSal}
                    alt="image"
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Link>
                <Link
                  href="https://ec.europa.eu/consumers/odr"
                  target="_blank"
                  sx={{ color: "#ffffff", scale: "100%" }}
                >
                  <CardMedia
                    component="img"
                    image={AnpcSol}
                    alt="image"
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Link>

              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ height: "1px", backgroundColor: "#ff5722" }} />
        <Box sx={{ py: 2 }}>
          {" "}
          <Typography sx={{ color: "#ffffff" }}>
            SC Briana Travel SRL
          </Typography>
          <Typography sx={{ color: "#ffffff" }}>CUI: RO18857533</Typography>
          <Typography sx={{ color: "#ffffff" }}>
            Nr. REG: J22/1744/2006
          </Typography>
        </Box>
      </Container>
    </FooterBg>
  );
}

export default Footer;
