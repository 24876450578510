import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import ShareIcon from "@mui/icons-material/Share";
//////
import WhatsAppIconButton from "./WhatsUp";
import FacebookIconButton from "./Facebook";
import TikTokIconButton from "./Tiktok";
/////

export const NavMenu = [
  {
    id: 1,
    link: "/",

    text: "Acasa",
  },
  {
    id: 2,
    link: "/About",

    text: "Despre Noi",
  },
  {
    id: 3,
    link: "/Italia",

    text: "Traseu Italia",
  },
  {
    id: 4,
    link: "/Romania",

    text: "Traseu Romania",
  },
  {
    id: 5,
    link: "/News",

    text: "Noutati",
  },
  {
    id: 7,
    link: "/Galerie",
    text: "Galerie",
  },
  {
    id: 6,
    link: "/Contact",

    text: "Contact",
  },
];
export const OrangeColor = "#ff5722";

export const ContactList = [
  {
    id: 1,
    icon: <BusinessIcon style={{ fontSize: { lg: "large", md: "small" } }} />,
    textTitle: "Punct de Lucru",
    subtitle1: "Soseaua Nationala nr.80, incinta bazei sportive CFR",
    subtitle2: "Cartier Alexandru Cel Bun, Iasi, Romania",
  },
  {
    id: 2,
    icon: <PhoneIcon style={{ fontSize: { lg: "large", md: "small" } }} />,
    textTitle: "Telefon",
    subtitle1: (
      <a
        href="tel:0040.745.90.29.90"
        style={{ color: OrangeColor, textDecoration: "none" }}
      >
        0040.745.90.29.90 - Romania
      </a>
    ),
    subtitle2: (
      <a
        href="tel:0040.742.99.68.99"
        style={{ color: OrangeColor, textDecoration: "none" }}
      >
        0040.742.99.68.99 - Romania
      </a>
    ),
    subtitle3: (
      <a
        href="tel:0039.3802.451.763"
        style={{ color: OrangeColor, textDecoration: "none" }}
      >
        0039.3802.451.763 - Italia
      </a>
    ),
    subtitle4: (
      <a
        href="tel:0039.3281.797.466"
        style={{ color: OrangeColor, textDecoration: "none" }}
      >
        0039.3281.797.466 - Italia
      </a>
    ),
  },
  {
    id: 3,
    icon: <ShareIcon style={{ fontSize: { lg: "large", md: "small" } }} />,
    textTitle: "Social Media",
    subtitle1: (
      <div style={{ marginTop: "12px" }}>
        <WhatsAppIconButton />
        <FacebookIconButton />
        <TikTokIconButton />
      </div>
    ),
  },
  {
    id: 4,
    icon: <EmailIcon style={{ fontSize: { lg: "large", md: "small" } }} />,
    textTitle: "Email",
    subtitle1: (
      <a
        href="mailto:brianatravel_brianatravel@yahoo.com"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        brianatravel_brianatravel @yahoo.com
      </a>
    ),
    subtitle2: (
      <a
        href="mailto:brianatravel_office2@yahoo.com"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        brianatravel_office2 @yahoo.com
      </a>
    ),
    subtitle3: (
      <a
        href="mailto:brianatravel.is@gmail.com"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        brianatravel.is@gmail.com{" "}
      </a>
    ),
  },
];
