import React from "react";
import colete from "../assets/colete.jpg";
import BusExterior from "../assets/BusExterior.jpg";
import Interior from "../assets/Interior.jpg";
import colete2 from "../assets/colete2.jpg";
import { styled } from "@mui/system";
import bus4 from "../assets/bus4.jpg";

import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Fade from "react-reveal/Fade";
import Flash from "react-reveal/Flash";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhyUs from "../components/whyUs";
import Intrebari from "../components/Intrebari";
import Noutati from "../components/Noutati";
import Traseu from "../components/Traseu";

const HomePageWrapper = styled("CardMedia")({
  position: "relative",
  marginTop: 0,
  height: "100vh",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
});
function Home() {
  return (
    <div style={{ marginTop: 0 + "!important" }}>
      <HomePageWrapper>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            image={`${bus4}`}
            sx={{
              position: "absolute",
              height: "90vh",
              objectFit: "cover",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: -1,
            }}
          />
          <Box
            id="1"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="90vh"
            sx={{ zIndex: 1 }}
          >
            <Fade top>
              <Box
                height="auto"
                width="fit-content"
                p={5}
                color="white"
                sx={{
                  position: "relative",
                  backgroundColor: "rgba(255,87,34, 0.6)",
                }}
              >
                <Fade bottom>
                  <Box sx={{ zIndex: 100 }}>
                    <Typography component="h1" variant="h4">
                      Transport Internațional
                    </Typography>
                    <Typography component="h1" variant="h4">
                      Persoane
                    </Typography>
                    <Typography component="h1" variant="h5">
                      Coletărie / Marfă
                    </Typography>
                    <Typography component="h3" variant="h6">
                      România{" "}
                      <KeyboardDoubleArrowRightIcon
                        fontSize="small"
                        sx={{ verticalAlign: "middle" }}
                      />{" "}
                      Italia
                    </Typography>
                    <Typography component="h3" variant="h6">
                      Italia{" "}
                      <KeyboardDoubleArrowRightIcon
                        fontSize="small"
                        sx={{ verticalAlign: "middle" }}
                      />{" "}
                      România
                    </Typography>
                  </Box>
                </Fade>
              </Box>
            </Fade>
            <Box
              id="2"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: 2,
                //backgroundColor: "white",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Container>
                <Grid
                  container
                  spacing={2}
                  sx={{ color: "#ffffff", textAlign: "center" }}
                >
                  {/* Four columns on md and up */}
                  <Grid item xs={12} sm={6} md={3}>
                    <AccessTimeIcon
                      fontSize="large"
                      sx={{ color: "#ff5722" }}
                    />
                    <Typography component="h6">Luni-Duminica</Typography>
                    <Typography component="h6">24/7</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <PeopleAltIcon fontSize="large" sx={{ color: "#ff5722" }} />
                    <Typography component="h6">
                      Transport Internațional Persoane
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Inventory2Icon
                      fontSize="large"
                      sx={{ color: "#ff5722" }}
                    />
                    <Typography component="h6">
                      Transport internațional.
                    </Typography>
                    <Typography component="h6">
                      Livrare rapidă a mărfurilor.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <LocalShippingIcon
                      fontSize="large"
                      sx={{ color: "#ff5722" }}
                    />
                    <Typography component="h6">
                      Serviciu modern de transport auto.
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </Box>
      </HomePageWrapper>
      <Box pb={3} sx={{ backgroundColor: "#f3f5f6" }}>
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Fade left>
              <Typography
                //px={5}
                pt={5}
                variant="h5"
                component="h2"
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  letterSpacing: 3,
                  fontFamily: "Arial",
                }}
              >
                SIGURANȚA TA ESTE PRIORITATEA NOASTRĂ NR.1
              </Typography>
            </Fade>
            <Grid container spacing={3} py={5}>
              {/* Four columns on md and up */}
              <Grid item xs={12} sm={6} md={4}>
                <Fade left>
                  <Card sx={{ boxShadow: 3 }}>
                    <CardMedia
                      component="img"
                      alt="colete"
                      height="240"
                      image={BusExterior}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TRANSPORT INTERNAȚIONAL DE PERSOANE ROMÂNIA ITALIA
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Călătorește în siguranță și confort cu serviciile
                        noastre de transport internațional de persoane între
                        România și Italia. Rezervă acum!
                      </Typography>
                    </CardContent>
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Fade bottom>
                  <Card sx={{ boxShadow: 3 }}>
                    <CardMedia
                      component="img"
                      alt="italia"
                      height="240"
                      image={Interior}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        TRANSPORT INTERNAȚIONAL DE PERSOANE ITALIA ROMÂNIA
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Călătorește fără griji între Italia și România cu
                        serviciile noastre de transport internațional de
                        persoane. Rezervă acum locul tău!
                      </Typography>
                    </CardContent>
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Fade right>
                  <Card sx={{ boxShadow: 3 }}>
                    <CardMedia
                      component="img"
                      alt="colete"
                      height="240"
                      image={colete2}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        SERVICII DE TRANPORT INTERNAȚIONAL DE COLETE ȘI MARFĂ
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Expediază colete și mărfuri în siguranță între Romania
                        și Italia cu serviciile noastre de transport. Rapiditate
                        și eficiență la prețuri competitive!
                      </Typography>
                    </CardContent>
                  </Card>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container>
          <Flash>
            <Box sx={{ backgroundColor: "rgba(255,87,34, 0.9)" }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{ textAlign: "center" }}
                py={5}
              >
                {" "}
                <PhoneAndroidIcon fontSize="large" />
                Sună <b>acum</b> pentru detalii și <b>rezervare</b>{" "}
                <u>0745 902 990</u>
              </Typography>
            </Box>
          </Flash>
        </Container>
      </Box>
      <Traseu />
      <WhyUs sx={{ marginTop: "10px" }} />
      <Noutati />
      <Intrebari />
    </div>
  );
}

export default Home;
