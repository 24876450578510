import React from "react";
import {
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
} from "@mui/material";
import Braila from "../assets/Braila.jpeg";
import Iasi from "../assets/Iasi.jpeg";
import Torino from "../assets/Torino.jpeg";

import Roma from "../assets/Roma.jpeg";
function Traseu() {
  return (
    <div>
      <Container>
        <Box sx={{ py: 5 }}>
          <Typography variant="h3" sx={{ color: "#ff5722", mb: 2 }}>
            Cateva din destinatiile in care ajungem
          </Typography>
          <Box>
            <Typography variant="h5">Trasee Complete</Typography>
            <Box sx={{ my: 3 }}>
              <Button
                variant="outlined"
                color="error"
                size="large"
                sx={{ mr: 2 }}
              >
                Traseu Romania
              </Button>
              <Button variant="outlined" color="error" size="large">
                Traseu Italia
              </Button>
            </Box>
          </Box>
          <Typography variant="h6" sx={{ pb: 4 }}>
            Ajungem în cele mai importante destinații din Romania și Italia,
            inclusiv Braila, Iasi, Torino, Roma și multe altele! Rezervă acum și
            beneficiezi de servicii de transport sigure, rapide și la prețuri
            competitive. Noi facem legătura între cele două țări pentru că ne
            pasă de nevoile tale de transport!
          </Typography>
          <Divider sx={{ height: "1px", backgroundColor: "#ff5722" }} />
          <Grid container spacing={2} sx={{ my: 5 }}>
            <Grid item xs={12} md={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={Braila}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Braila
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={Iasi}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Iasi
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={Torino}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Torino
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={Roma}
                  title="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Roma
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Traseu;
