import React from "react";
import { styled } from "@mui/system";
import Interior2 from "../assets/Interior2.jpg";
import {
  Box,
  Container,
  Typography,
  Card,
  CardHeader,
  Avatar,
  Grid,
} from "@mui/material";
import Fade from "react-reveal/Fade";

const SeatsBus = styled("div")({
  backgroundImage: `url(${Interior2})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  height: "auto",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
});

const color = "#ff5722";

function WhyUs() {
  return (
    <div>
      <SeatsBus>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            component="h4"
            sx={{ color: `${color}` }}
            variant="h5"
            pt={4}
          >
            De ce{" "}
            <span style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
              Briana Travel
            </span>
          </Typography>
          <Typography
            component="h4"
            sx={{ fontSize: "2rem", color: "#ffffff" }}
          >
            Călătorind cu noi, satisfacția ta este garantată!
          </Typography>
          <Typography sx={{ color: "#ffffff" }}>
            Suntem alegerea perfectă deoarece îți oferim servicii de transport
            de înaltă calitate la prețuri competitive! Echipa noastră este
            formată din profesioniști cu experiență, care vor asigura confortul
            și siguranța călătoriei tale.
          </Typography>
          <Box>
            <Grid container spacing={3} py={5}>
              <Grid item xs={12} sm={6} md={6}>
                <Fade left>
                  <Card
                    sx={{
                      maxWidth: 345,
                      backgroundColor: "transparent",
                      color: "#ffffff",
                      borderRadius: 0,
                      boxShadow: 0,
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ backgroundColor: `${color}` }}
                          aria-label="recipe"
                        >
                          !
                        </Avatar>
                      }
                      title={
                        <Typography variant="h5" sx={{ fontSize: 24 }}>
                          Siguranță și securitate
                        </Typography>
                      }
                      subheaderTypographyProps={{
                        color: "white",
                      }}
                    />
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Fade left>
                  <Card
                    sx={{
                      maxWidth: 500,
                      backgroundColor: "transparent",
                      color: "#ffffff",
                      borderRadius: 0,
                      boxShadow: 0,
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ backgroundColor: `${color}` }}
                          aria-label="recipe"
                        >
                          !
                        </Avatar>
                      }
                      title={
                        <Typography variant="h5" sx={{ fontSize: 24 }}>
                          Conducători auto profesioniști, cu o conduită
                          impecabilă
                        </Typography>
                      }
                      subheaderTypographyProps={{
                        color: "white",
                      }}
                    />
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Fade right>
                  <Card
                    sx={{
                      maxWidth: 500,
                      backgroundColor: "transparent",
                      color: "#ffffff",
                      borderRadius: 0,
                      boxShadow: 0,
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ backgroundColor: `${color}` }}
                          aria-label="recipe"
                        >
                          !
                        </Avatar>
                      }
                      title={
                        <Typography variant="h5" sx={{ fontSize: 24 }}>
                          Punctuali și corecți
                        </Typography>
                      }
                      subheaderTypographyProps={{
                        color: "white",
                      }}
                    />
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Fade right>
                  <Card
                    sx={{
                      maxWidth: 500,
                      backgroundColor: "transparent",
                      color: "#ffffff",
                      borderRadius: 0,
                      boxShadow: 0,
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ backgroundColor: `${color}` }}
                          aria-label="recipe"
                        >
                          !
                        </Avatar>
                      }
                      title={
                        <Typography variant="h5" sx={{ fontSize: 24 }}>
                          Autovehicule foarte bine întreținute mecanic, estetic
                          și cu dotări moderne
                        </Typography>
                      }
                      subheaderTypographyProps={{
                        color: "white",
                      }}
                    />
                  </Card>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SeatsBus>
    </div>
  );
}
export default WhyUs;
