import BgItalia from "../assets/roadItalia.jpg";
import { styled } from "@mui/material";
import { Typography, Container, Box, Grid, Divider } from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Fade from "react-reveal/Fade";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { OrangeColor } from "../components/Const";
import {
  TraseuItaliaUnu,
  TraseuItaliaDoi,
  TraseuItaliaTrei,
} from "../components/arrays/TraseuItalia";
function Italia() {
  const ItaliaRoad = styled("div")({
    backgroundImage: `url(${BgItalia})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    // marginTop: 0,
    height: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });
  return (
    <div>
      <ItaliaRoad>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Fade top>
            <Typography
              textAlign="center"
              variant="h2"
              component="h3"
              color={OrangeColor}
              sx={{ justifyContent: "center", pt: 18 }}
            >
              Trasee Italia
            </Typography>
          </Fade>
          <Fade bottom>
            <Typography
              textAlign="center"
              variant="h5"
              component="h4"
              color={"white"}
              sx={{ justifyContent: "center", py: 5 }}
            >
              Optați pentru călătoria cu autocarele noastre pentru a beneficia
              de siguranță și confort pe parcursul călătoriei.
            </Typography>
          </Fade>
        </Container>
      </ItaliaRoad>
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        <Container sx={{ backgroundColor: "#ffffff" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
                Traseu 1
              </Typography>
              <Typography sx={{ textAlign: "center", color: OrangeColor }}>
                Italia
              </Typography>
              <Timeline position="alternate">
                {TraseuItaliaUnu.map((item) => (
                  <>
                    <TimelineItem key={item.id}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", overflow: "hidden" }}
                        align="right"
                        variant="h6"
                      >
                        {item.oras}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                        <TimelineDot sx={{ backgroundColor: OrangeColor }}>
                          <ArrowDropDownIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="div">
                          <img
                            style={{
                              height: 98,
                              width: 98,
                              borderRadius: 8,
                            }}
                            src={item.img}
                            alt={item.oras}
                          />
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: OrangeColor,
                  mb: 3,
                  fontWeight: "bold",
                }}
              >
                Retur
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              <Divider
                sx={{
                  display: { sm: "block", md: "none" },
                  height: "2px",
                  backgroundColor: OrangeColor,
                }}
              />
              <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
                Traseu 2
              </Typography>
              <Typography sx={{ textAlign: "center", color: OrangeColor }}>
                Italia
              </Typography>
              <Timeline position="alternate">
                {TraseuItaliaDoi.map((item) => (
                  <>
                    <TimelineItem key={item.id}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", overflow: "hidden" }}
                        align="right"
                        variant="h6"
                        //color="text.secondary"
                      >
                        {item.oras}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                        <TimelineDot sx={{ backgroundColor: OrangeColor }}>
                          <ArrowDropDownIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="div">
                          <img
                            style={{
                              height: 98,
                              width: 98,
                              borderRadius: 8,
                            }}
                            src={item.img}
                            alt={item.oras}
                          />
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: OrangeColor,
                  mb: 3,
                  fontWeight: "bold",
                }}
              >
                Retur
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Divider
                sx={{
                  display: { sm: "block", md: "none" },
                  height: "2px",
                  backgroundColor: OrangeColor,
                }}
              />
              <Typography variant="h4" sx={{ textAlign: "center", mt: 2 }}>
                Traseu 3
              </Typography>
              <Typography sx={{ textAlign: "center", color: OrangeColor }}>
                Italia
              </Typography>
              <Timeline position="alternate">
                {TraseuItaliaTrei.map((item) => (
                  <>
                    <TimelineItem key={item.id}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0", overflow: "hidden" }}
                        align="right"
                        variant="h6"
                        //color="text.secondary"
                      >
                        {item.oras}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                        <TimelineDot sx={{ backgroundColor: OrangeColor }}>
                          <ArrowDropDownIcon />
                        </TimelineDot>
                        <TimelineConnector
                          sx={{ backgroundColor: OrangeColor, width: 3 }}
                        />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Typography variant="h6" component="div">
                          <img
                            style={{
                              height: 98,
                              width: 98,
                              borderRadius: 8,
                            }}
                            src={item.img}
                            alt={item.oras}
                          />
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))}
              </Timeline>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  color: OrangeColor,
                  mb: 3,
                  fontWeight: "bold",
                }}
              >
                Retur
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default Italia;
