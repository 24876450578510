import React from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppButton = styled(IconButton)({
  backgroundColor: "#25D366", // WhatsApp green color
  color: "#fff",
  "&:hover": {
    backgroundColor: "#128C7E", // WhatsApp dark green color on hover
  },
});

const handleWhatsAppClick = () => {
  window.open("https://api.whatsapp.com/send?phone=+40.745.90.29.90", "_blank");
};
const WhatsAppIconButton = () => (
  <WhatsAppButton onClick={handleWhatsAppClick} aria-label="WhatsApp">
    <WhatsAppIcon />
  </WhatsAppButton>
);

export default WhatsAppIconButton;
