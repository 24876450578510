import BgDespreNoi from "../assets/mybus/Interior.jpeg";
import { Container } from "@mui/system";
import React from "react";
import Typography from "@mui/material/Typography";
import { styled, List, ListItem } from "@mui/material";
import { OrangeColor } from "../components/Const";

function About() {
  const DespreNoi = styled("div")({
    backgroundImage: `url(${BgDespreNoi})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });
  return (
    <div>
      <DespreNoi>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            textAlign="center"
            variant="h2"
            component="h3"
            color={OrangeColor}
            sx={{ justifyContent: "center", pt: 12, pb: 4 }}
          >
            Despre Noi
          </Typography>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem>
              <Typography
                textAlign="center"
                variant="h5"
                component="h4"
                color={"white"}
                sx={{ justifyContent: "center", py: 1 }}
              >
                Suntem SC BRIANA TRAVEL SRL Iași , o societate dinamică și cu o
                poziție bine stabilită, companie românească cu capital privat,
                cunoscută drept una dintre firmele reprezentative din piață
                românească, care oferă servicii de calitate superioară și
                aceasta datorită “cărții noastre de vizită”: Calitate & Preț &
                Performanță.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                textAlign="center"
                variant="h5"
                component="h4"
                color={"white"}
                sx={{ justifyContent: "center" }}
              >
                Cu o experință de 21 ani în domeniul transportului internațional
                de persoane și marfă, suntem parte din viață celor care au ales
                să călătorească cu noi, sau a celor stabiliți în Italia pentru
                care România reprezintă tot “acasă”.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                textAlign="center"
                variant="h5"
                component="h4"
                color={"white"}
                sx={{ justifyContent: "center" }}
              >
                Pe parcursul a celor 2 decenii am căutat să ne perfecționăm, să
                îmbunătățim condițiile de confort oferite, să modernizăm în mod
                constant parcul auto, să investim în profesionalismul și
                conduita întregului personal dar mai ales în conduita
                conducătorilor auto.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                textAlign="center"
                variant="h5"
                component="h4"
                color={"white"}
                sx={{ justifyContent: "center", pb: "8rem" }}
              >
                Briana Travel Iași, a ales să presteze servicii de transport de
                calitate și nu de cantitate, selectandu-și atent clienții și
                oferindu-le acestora cele mai bune condiții de călătorie.
              </Typography>
            </ListItem>
          </List>
        </Container>
      </DespreNoi>
    </div>
  );
}

export default About;
