import * as React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { Typography, Grid } from "@mui/material";

import Oferte from "../assets/program.jpeg";
import Image from "../assets/easter.jpg";
import PromoVideo1 from '../assets/promovideo3.mp4'
import PromoVideo2 from '../assets/promovideo2.mp4'

export default function CardNoutati() {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="img"
          image={Oferte}
          alt="image"
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "auto",
          }}
        />
      </Card>
      <Card sx={{ display: "flex", mt: 5, boxShadow: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardMedia
                sx={{ flex: "1 0 auto" }}
                component="img"
                image={Image}
                alt="image"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  5 Mai 2024
                </Typography>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ color: "#ff5722", fontWeight: "bold" }}
                >
                  Paște Creștin Ortodox
                </Typography>
                <Typography></Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="video"
          src={PromoVideo1}
          alt="Promo Video"
          controls
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "auto",
          }}
        />
      </Card>
      <Card
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
          mt: 5,
        }}
      >
        <CardMedia
          component="video"
          src={PromoVideo2}
          alt="Promo Video"
          controls
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "auto",
          }}
        />
      </Card>
    </>
  );
}
