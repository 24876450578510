import React from "react";

import Maps from "../components/Maps";
import GoogleMapIframe from "../components/GoogleFrame";
import { styled } from "@mui/system";
import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

import Fade from "react-reveal/Fade";
import BgContact from "../assets/contact.jpg";
import { ContactList, OrangeColor } from "../components/Const";

function Contact() {
  const Contact = styled("div")({
    backgroundImage: `url(${BgContact})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "auto",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1,
    },
  });
  return (
    <section>
      <Contact>
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Fade top>
            <Typography
              textAlign="center"
              variant="h2"
              component="h3"
              color={OrangeColor}
              sx={{ justifyContent: "center", pt: 18 }}
            >
              Contactați-ne
            </Typography>
          </Fade>
          <Fade bottom>
            <Typography
              textAlign="center"
              variant="h5"
              component="h4"
              color={"white"}
              sx={{ justifyContent: "center", py: 5 }}
            >
              Alegeți călătoria cu autocarele noastre pentru a vă bucura de
              siguranță și confort în timpul deplasării.
            </Typography>
          </Fade>
        </Container>
      </Contact>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Fade left>
              <List sx={{ width: "100%" }}>
                {ContactList.map((item) => (
                  <ListItem key={item.id} item={item} sx={{ px: 0 }}>
                    <ListItemAvatar sx={{ mr: { md: 5, sm: 2 } }}>
                      <Avatar
                        sx={{
                          width: { lg: 60, md: 50, sm: 40 },
                          height: { lg: 60, md: 50, sm: 40 },
                          backgroundColor: OrangeColor,
                        }}
                      >
                        {item.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography
                            variant="h5"
                            sx={{
                              borderBottom: `2px solid ${OrangeColor}`,
                              fontWeight: "bold",
                            }}
                          >
                            {item.textTitle}
                          </Typography>
                        </>
                      }
                      secondary={
                        <>
                          <Typography variant="h6" color="textSecondary">
                            {item.subtitle1}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {item.subtitle2}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {item.subtitle3}
                          </Typography>
                          <Typography variant="h6" color="textSecondary">
                            {item.subtitle4}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Fade>
          </Grid>
          <Grid item xs={12} md={6}>
            <Fade right>
              <GoogleMapIframe />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Contact;
