import Trieste from "../../assets/MiniCity/Italia/Trieste.jpg";
import Mestre from "../../assets/MiniCity/Italia/Mestre.jpg";
import Padova from "../../assets/MiniCity/Italia/Padova.jpg";
import Verona from "../../assets/MiniCity/Italia/Verona.jpg";
import Brescia from "../../assets/MiniCity/Italia/Brescia.jpg";
import Cremona from "../../assets/MiniCity/Italia/Cremona.jpg";
import Voghera from "../../assets/MiniCity/Italia/Voghera.jpg";
import Piacenza from "../../assets/MiniCity/Italia/Piacenza.jpg";
import Tortona from "../../assets/MiniCity/Italia/Tortona.jpg";
import Alessandria from "../../assets/MiniCity/Italia/Alessandria.jpg";
import Asti from "../../assets/MiniCity/Italia/Asti.jpg";
import Alba from "../../assets/MiniCity/Italia/Alba.jpg";
import Cuneo from "../../assets/MiniCity/Italia/Cuneo.jpg";
import Bra from "../../assets/MiniCity/Italia/Bra.jpg";
import Torino from "../../assets/MiniCity/Italia/Torino.jpg";
////
import Palazzolo from "../../assets/MiniCity/Italia/Palazzolo.jpg";
import Bergamo from "../../assets/MiniCity/Italia/Bergamo.jpg";
import Milano from "../../assets/MiniCity/Italia/Milano.jpg";
import Novara from "../../assets/MiniCity/Italia/Novara.jpg";
import Chivasso from "../../assets/MiniCity/Italia/Chivasso.jpg";
////
import Monselice from "../../assets/MiniCity/Italia/Monselice.jpg";
import Rovigo from "../../assets/MiniCity/Italia/Rovigo.jpg";
import Ferrara from "../../assets/MiniCity/Italia/Ferrara.jpg";
import Bologna from "../../assets/MiniCity/Italia/Bologna.jpg";
import Prato from "../../assets/MiniCity/Italia/Prato.jpg";
import Firenze from "../../assets/MiniCity/Italia/Firenze.jpg";
import Roma from "../../assets/MiniCity/Italia/Roma.jpg";

export const TraseuItaliaUnu = [
  {
    id: 1,
    oras: "Trieste",
    img: Trieste,
  },
  {
    id: 2,
    oras: "Mestre",
    img: Mestre,
  },
  {
    id: 3,
    oras: "Padova",
    img: Padova,
  },
  {
    id: 4,
    oras: "Verona",
    img: Verona,
  },
  {
    id: 5,
    oras: "Brescia",
    img: Brescia,
  },
  {
    id: 6,
    oras: "Cremona",
    img: Cremona,
  },
  {
    id: 7,
    oras: "Piacenza",
    img: Piacenza,
  },
  {
    id: 8,
    oras: "Voghera",
    img: Voghera,
  },
  {
    id: 9,
    oras: "Tortona",
    img: Tortona,
  },
  {
    id: 10,
    oras: "Alessandria",
    img: Alessandria,
  },
  {
    id: 11,
    oras: "Asti",
    img: Asti,
  },
  {
    id: 12,
    oras: "Alba",
    img: Alba,
  },
  {
    id: 13,
    oras: "Cuneo",
    img: Cuneo,
  },
  {
    id: 14,
    oras: "Bra",
    img: Bra,
  },
  {
    id: 15,
    oras: "Torino",
    img: Torino,
  },
];
export const TraseuItaliaDoi = [
  {
    id: 1,
    oras: "Trieste",
    img: Trieste,
  },
  {
    id: 2,
    oras: "Mestre",
    img: Mestre,
  },
  {
    id: 3,
    oras: "Padova",
    img: Padova,
  },
  {
    id: 4,
    oras: "Verona",
    img: Verona,
  },
  {
    id: 5,
    oras: "Brescia",
    img: Brescia,
  },
  {
    id: 6,
    oras: "Palazzolo",
    img: Palazzolo,
  },
  {
    id: 7,
    oras: "Bergamo",
    img: Bergamo,
  },
  {
    id: 8,
    oras: "Milano",
    img: Milano,
  },
  {
    id: 9,
    oras: "Novara",
    img: Novara,
  },
  {
    id: 10,
    oras: "Chivasso",
    img: Chivasso,
  },
  {
    id: 11,
    oras: "Torino",
    img: Torino,
  },
];

export const TraseuItaliaTrei = [
  {
    id: 1,
    oras: "Trieste",
    img: Trieste,
  },
  {
    id: 2,
    oras: "Mestre",
    img: Mestre,
  },
  {
    id: 3,
    oras: "Padova",
    img: Padova,
  },
  {
    id: 4,
    oras: "Monselice",
    img: Monselice,
  },
  {
    id: 5,
    oras: "Rovigo",
    img: Rovigo,
  },
  {
    id: 6,
    oras: "Ferrara",
    img: Ferrara,
  },
  {
    id: 7,
    oras: "Bologna",
    img: Bologna,
  },
  {
    id: 8,
    oras: "Prato",
    img: Prato,
  },
  {
    id: 9,
    oras: "Firenze",
    img: Firenze,
  },
  {
    id: 10,
    oras: "Roma",
    img: Roma,
  },
];
